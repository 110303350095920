<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="invoices"
                          base-table="invoice"
                          columnsPrefix="invoices.column."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          :selectedSource="selectedSource"
                          :tbody-tr-class="rowClass"
                          title="common.menu.account-book.invoice"
                          :actions-list="getActions()"
                >
                    <template v-slot:top-actions>
                        <b-form inline class="mb-2">
                            <font-awesome-icon
                                role="button"
                                icon="chevron-left"
                                @click="prevMonth()"
                            />&nbsp;
                            <b-form-select
                                v-model="month"
                                :options="monthsList()"
                                class="mr-2"
                                @change="monthChanged"
                            ></b-form-select>
                            <font-awesome-icon
                                role="button"
                                icon="chevron-right"
                                @click="nextMonth()"
                            />

                            <b-form-select
                                v-model="month"
                                :options="yearsList()"
                                class="ml-3"
                                @change="yearChanged"
                            ></b-form-select>
                        </b-form>
                    </template>

                    <template #cell(actions)="row">
                        <b-dropdown class="mb-1"
                                    left
                                    variant="primary"
                                    size="sm"
                        >
                            <template #button-content>
                                {{ $t('common.label.actions') }}
                            </template>

                            <b-dropdown-item
                                @click="sendEmail(row.item)">
                                <font-awesome-icon class="mr-2" icon="envelope"/>
                                {{ $t('common.button.send_mail') }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'
import tableDateFilter from '@/bundles/erika_common_bundle/mixins/table-date-filter'

export default {
    name: 'Invoices',
    mixins: [commonTable, tableDateFilter],
    data() {
        return {
            dateField: 'createdAt',
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('Invoices', ['getInvoicesTable']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Invoices/fetchInvoices', 'getInvoicesTable');
        },
        sendEmail(invoice, type) {
            let lastInvoice = invoice.documents[invoice.documents.length - 1] || null
            if (lastInvoice) {
                this.$root.$children[0].openModal('email-modal', {documentId: lastInvoice.id}, null, {width: '800px'})
            }
        },
        markPaidInvoices() {
            this.$root.$children[0].openModal('paid-modal', this.selected.map(row => row.id), this.refreshTable, {width: '800px'});
        },
        markUnpaidInvoices() {
            this.$unpaidConfirm('Invoices/markUnpaidInvoices', this.selected.map(row => row.id), this.refreshTable)
        },
        cancelInvoices() {
            this.$root.$children[0].openModal('invoice-cancel-modal', this.selected.map(row => row.id), this.refreshTable, {width: '800px'});
        },
        uncancelInvoices() {
            this.$cancelConfirm('Invoices/uncancelInvoices', this.selected.map(row => row.id), this.refreshTable)
        },
        rowClass(item) {
            if (item) {
                let row_class = '';
                if (item.is_canceled) {
                    row_class += ' canceled';
                }

                if (item.entity_status == 'paid') {
                    row_class += ' paid';
                }
                if (item.entity_status == 'unpaid') {
                    row_class += ' unpaid';
                }
                if (item.entity_status == 'partial_paid') {
                    row_class += ' partial_paid';
                }

                return row_class;
            }
        },
        allowPaid(selected) {
            if (selected.length > 0) {
                for (let item of selected) {
                    if (item.is_canceled) {
                        return false;
                    }
                    if (item.payment_date_timestamp) {
                        return false;
                    }
                }
                return true;
            }
        },
        allowUnpaid(selected) {
            if (selected.length > 0) {
                for (let item of selected) {
                    if (item.is_canceled) {
                        return false;
                    }
                }
                return true;
            }
        },
        allowCancel(selected) {
            if (selected.length > 0) {
                for (let item of selected) {
                    if (item.is_canceled) {
                        return false;
                    }
                    if (item.payment_date_timestamp) {
                        return false;
                    }
                }
                return true;
            }
        },
        allowUncancel(selected) {
            if (selected.length > 0) {
                for (let item of selected) {
                    if (!item.is_canceled) {
                        return false;
                    }
                }
                return true;
            }
        },
        getActions() {
            return [
                {
                    title: this.$t('common.button.create'),
                    icon: 'plus',
                    click: () => {
                        this.$root.$children[0].openModal('project-form', {}, null, {width: '800px'})
                    }
                },
                {
                    title: this.$t('invoices.button.mark_paid'),
                    icon: null,
                    disabled: !this.allowPaid(this.selected),
                    click: () => {
                        this.markPaidInvoices()
                    }
                },
                {
                    title: this.$t('invoices.button.mark_unpaid'),
                    icon: null,
                    disabled: !this.allowUnpaid(this.selected),
                    click: () => {
                        this.markUnpaidInvoices()
                    }
                },
                {
                    title: this.$t('invoices.button.mark_cancel'),
                    icon: null,
                    disabled: !this.allowCancel(this.selected),
                    click: () => {
                        this.cancelInvoices()
                    }
                },
                {
                    title: this.$t('invoices.button.mark_uncancel'),
                    icon: null,
                    disabled: !this.allowUncancel(this.selected),
                    click: () => {
                        this.uncancelInvoices()
                    }
                },
            ]
        }
    },
}
</script>